import "@ethersproject/shims"
import { BigNumber, ethers } from "ethers";
import toast from "react-hot-toast";
import { getContractObj } from ".";
import { NFTMintEngineDetail } from "./typs";

export async function purchaseGold(chainId, provider, account, numberOfTokens) {
    const SuperSquatchContract = getContractObj('SuperSquatch', chainId, provider);
    try {
        const ownerAddress: string = await SuperSquatchContract.owner();
        var nftPrice: BigNumber = BigNumber.from(0);

        if (ownerAddress.toLocaleLowerCase() === account.toLocaleLowerCase())
        {
            nftPrice = BigNumber.from(0);

            const tx = await SuperSquatchContract.giftGold(account, numberOfTokens);
            await tx.wait(1);

        } else {
            nftPrice = await SuperSquatchContract.GOLD_PRICE();

            const tx = await SuperSquatchContract.purchaseGold(numberOfTokens, {
                value: nftPrice.mul(numberOfTokens)
            });
            await tx.wait(1);

        }

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function purchasePrize(chainId, provider, account, numberOfTokens) {
    const SuperSquatchContract = getContractObj('SuperSquatch', chainId, provider);
    try {
        const ownerAddress: string = await SuperSquatchContract.owner();
        var nftPrice: BigNumber = BigNumber.from(0);

        if (ownerAddress.toLocaleLowerCase() === account.toLocaleLowerCase()) {
            nftPrice = BigNumber.from(0);

            const tx = await SuperSquatchContract.giftPrize(account, numberOfTokens);
            await tx.wait(1);
        } else {
            nftPrice = await SuperSquatchContract.PRIZE_PRICE();

            const tx = await SuperSquatchContract.purchasePrize(numberOfTokens, {
                value: nftPrice.mul(numberOfTokens)
            });
            await tx.wait(1);
        }

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function purchasePublic(chainId, provider, account, numberOfTokens) {
    const SuperSquatchContract = getContractObj('SuperSquatch', chainId, provider);
    try {
        const ownerAddress: string = await SuperSquatchContract.owner();
        var nftPrice: BigNumber = BigNumber.from(0);

        if (ownerAddress.toLocaleLowerCase() === account.toLocaleLowerCase()) {
            nftPrice = BigNumber.from(0);

            const tx = await SuperSquatchContract.giftPublic(account, numberOfTokens);
            await tx.wait(1);
        } else {
            nftPrice = await SuperSquatchContract.PUBLIC_PRICE();

            const tx = await SuperSquatchContract.purchasePublic(numberOfTokens, {
                value: nftPrice.mul(numberOfTokens)
            });
            await tx.wait(1);
        }

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// export async function getEngineInfo(chainId, library) {
//     const SuperSquatchContract = getContractObj('SuperSquatch', chainId, library);
//     try {
//         const [
//             totalSupply,
//             maxSupply,
//             ownerAddress
//         ] = await Promise.all([
//             SuperSquatchContract.totalSupply(),
//             SuperSquatchContract.TSL_MAX(),
//             SuperSquatchContract.owner(),
//         ]);

//         const nftMintDetail: NFTMintEngineDetail = {
//             totalSupply: parseInt(totalSupply.toString()),
//             maxSupply: parseInt(maxSupply.toString()),
//             ownerAddress: ownerAddress.toString(),
//         }

//         return nftMintDetail;
//     } catch (e) {
//         console.log(e);
//         return null;
//     }
// }