import pic1 from "../assets/images/team1.png";
import pic2 from "../assets/images/team2.png";
import pic3 from "../assets/images/team3.png";
const Fade = require("react-reveal/Fade")
const Team = () => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-6xl mx-auto px-4 py-20">
        <Fade bottom>
          <h1 className="text-primary text-center text-xl pb-3 font-bold">
          Teamwork Makes the Dream Work
          </h1>
        </Fade>
        <Fade bottom>
          <h3 className="text-4xl text-center text-dark font-bold pb-4">
            Meet the Team
          </h3>
        </Fade>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-14">
          {data.map((item, index) => {
            return (
              <div key={index}>
                <Fade bottom>
                  <div className="flex justify-center">
                    <img src={item.picture} alt="" />
                  </div>

                  <h2 className="text-xl text-center font-bold text-dark">
                    {item.title}
                  </h2>
                </Fade>
              </div>
            );
          })}
        </div>
        <Fade bottom>
          <h1 className="text-primary text-center text-3xl pb-3 font-bold">
            Our team continues to grow, contact us if you’re interested!
          </h1>
        </Fade>
      </div>
    </div>
  );
};

export default Team;

const data = [
  {
    title: "Community Relations Lead",
    picture: pic1
  },
  {
    title: "Startegy Lead",
    picture: pic2
  },
  {
    title: "Design Team",
    picture: pic3
  }
];
