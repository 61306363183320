import vipPic from "../../src/assets/images/Diamond-ticket-1000p 1.png";
import listIcon from "../../src/assets/images/listicon.png";
const Fade = require("react-reveal/Fade")
const VIP = () => {
  return (
    <div className=" w-full bg-lightBlue">
      <div className="max-w-6xl mx-auto px-4 py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className=" order-last md:order-first">
            <Fade bottom>
              <img src={vipPic} alt="" />
            </Fade>
          </div>
          <div className="flex self-center">
            <div>
              <Fade bottom>
                <h1 className="text-primary text-xl pb-3 font-bold">
                  VIP Only
                </h1>
              </Fade>
              <Fade bottom>
                <h3 className="text-4xl text-dark font-bold pb-4">
                  The Squatch Pass
                </h3>
              </Fade>
              <Fade bottom>
                <p className="text-secondary text-xl font-semibold pb-7">
                  The only exclusive, shiny, ticket to VIP perks and benefits.
                </p>
              </Fade>
              <Fade bottom>
                <ul>
                  <li className="flex mb-3">
                    <span className="flex self-center">
                      <img src={listIcon} alt="" />
                    </span>
                    <span className="text-secondary text-lg font-semibold pl-3">
                      Only 300 Squatch passes exist!
                    </span>
                  </li>
                  <li className="flex mb-3">
                    <span className="flex self-center">
                      <img src={listIcon} alt="" />
                    </span>
                    <span className="text-secondary text-lg font-semibold pl-3">
                      Owners get 8 hours early access to Flash Drops!
                    </span>
                  </li>
                  <li className="flex mb-3">
                    <span className="flex self-center">
                      <img src={listIcon} alt="" />
                    </span>
                    <span className="text-secondary text-lg font-semibold pl-3">
                      Lucky VIP holders receive one free NFT per Main Drop.
                    </span>
                  </li>
                  <li className="flex mb-3">
                    <span className="flex self-center">
                      <img src={listIcon} alt="" />
                    </span>
                    <span className="text-secondary text-lg font-semibold pl-3">
                      Free NFT excludes Flash Drops 30 are reserved for
                      giveaways.
                    </span>
                  </li>
                </ul>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VIP;
