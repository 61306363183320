import {useState} from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {Drawer} from "antd";
import {GiHamburgerMenu} from "react-icons/gi";
import logo from "../assets/images/SS Logo 1.png";
import './mobMenu.css'
const MobileMenu = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const [isActiveMint, setIsActiveMint] = useState(false)
  return (
    <div>
      <div className="flex justify-between bg-white  z-50 h-20 fixed left-0 right-0 top-0 py-5 shadow-xl">
        <div className="pl-5 flex self-center">
          <img src={logo} alt="" className="w-40" />
        </div>
        <div className="mintDropDown_mob">
                  <button onClick = {()=>{setIsActiveMint(!isActiveMint)}}>MINT LAB</button>
                    <div className="dropList_mob" style = {{height: isActiveMint === true ? '6.5rem': '0'}}>
                      <ul>
                        <li><a href="/vippass" className="" onClick = {()=>{setIsActiveMint(false)}}>VIP Pass</a></li>
                        <li><a href="/squatchgames" className=""onClick = {()=>{setIsActiveMint(false)}}>Squatch Games</a></li>
                        <li><a href="/mintlab" className=""onClick = {()=>{setIsActiveMint(false)}}>Super Squatch</a></li>
                      </ul>
                     </div>
                  </div>
        <div className="pr-5 self-center cursor-pointer">
          <span onClick={showDrawer}>
            <GiHamburgerMenu className="w-8 h-8 text-dark" />
          </span>
        </div>
        
      </div>
      <Drawer placement="left" onClose={onClose} visible={visible}>
        <div className=" pt-10">
          <img src={logo} alt="" className="w-40" />
        </div>
        <div className="flex flex-col pt-7 ">
          <span className="text-lg font-medium text-dark transition-all hover:text-primary py-3.5 border-b border-gray-200 ">
            <AnchorLink href="#home">HOME</AnchorLink>
          </span>
          <span className="text-lg font-medium text-dark transition-all hover:text-primary py-3.5 border-b border-gray-200 ">
            <AnchorLink href="#VIP">VIP</AnchorLink>
          </span>
          <span className="text-lg font-medium text-dark transition-all hover:text-primary py-3.5 border-b border-gray-200 ">
            <AnchorLink href="#SQUATCH">SQUATCH</AnchorLink>
          </span>
          <span className="text-lg font-medium text-dark transition-all hover:text-primary py-3.5 border-b border-gray-200 ">
            <AnchorLink href="#STORY">STORY</AnchorLink>
          </span>
          <span className="text-lg font-medium text-dark transition-all hover:text-primary py-3.5 border-b border-gray-200 ">
            <AnchorLink href="#Community">COMMUNITY</AnchorLink>
          </span>
          <span className="text-lg font-medium text-dark transition-all hover:text-primary py-3.5 border-b border-gray-200 ">
            <AnchorLink href="#ROADMAP">ROADMAP</AnchorLink>
          </span>
          <span className="text-lg font-medium text-dark transition-all hover:text-primary py-3.5 border-b border-gray-200 ">
            <AnchorLink href="#TEAM">TEAM</AnchorLink>
          </span>
          <span className="text-lg font-medium text-dark transition-all hover:text-primary py-3.5 border-b border-gray-200 mb-10">
            <AnchorLink
              href="#FAQ"
             
            >
              FAQ
            </AnchorLink>
          </span>
          <a
            href="https://discord.gg/rbjGedNxUd"
            target="_blank"
            className="text-white bg-primary text-center mb-5 px-5 py-2 rounded-full transition-all hover:text-white hover:shadow-xl font-semibold cursor-pointer"
          >
            Join Discord
          </a>
          <a 
          href="https://twitter.com/supersquatchnft"
          target="_blank"
          className="text-white bg-primary text-center px-5 py-2 rounded-full transition-all hover:text-white hover:shadow-xl font-semibold cursor-pointer">
            Join Twitter
          </a>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileMenu;
