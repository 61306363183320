import {FaTelegramPlane, FaTwitter} from "react-icons/fa";
import leftIll from "../../src/assets/images/headerLeftillustartion.png";
import rightIll from "../../src/assets/images/headerrightillustartion.png";
import Marquee from "react-fast-marquee";

import pic1 from "../assets/images/slider/0triangle.png";
import pic2 from "../assets/images/slider/1082.png";
import pic3 from "../assets/images/slider/240.png";
import pic4 from "../assets/images/slider/500.png";
import pic5 from "../assets/images/slider/5031.png";
import pic6 from "../assets/images/slider/602.png";
import pic7 from "../assets/images/slider/618.png";
import pic8 from "../assets/images/slider/6928.png";
import pic9 from "../assets/images/slider/7052.png";
import pic10 from "../assets/images/slider/763.png";
import pic11 from "../assets/images/slider/87.png";
import pic12 from "../assets/images/slider/front man 2.0.png";
const Fade = require("react-reveal/Fade")
const Header = () => {
  return (
    <div className="w-full bg-white relative">
      <div>
        <img src={leftIll} alt="" className=" absolute top-0 left-0 " />
        <img src={rightIll} alt="" className=" absolute right-0 bottom-0" />
      </div>
      <div className="max-w-6xl mx-auto py-10 px-4 z-50 ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 z-50 ">
          <div>
            <Fade bottom>
              <h1 className="text-dark z-50  lineheight65 pt-20 md:pt-32 pb-2.5 font-bold">
                A Community Oriented Project Like No Other
              </h1>
            </Fade>
            <Fade bottom>
              <p className=" text-secondary text-lg md:text-2xl font-medium pb-16">
                Really, We mean it! We’ve raised the bar high when it comes to
                the exciting and unique benefits we are offering to our holders.
              </p>
            </Fade>

            <div className="flex flex-col sm:flex-row">
              <Fade bottom>
                <div className="flex justify-center btngradient px-4 mb-4 sm:mb-0  py-2.5 rounded-lg transition-all hover:shadow-xl">
                  <span className="flex self-center">
                    <FaTelegramPlane className="text-dark w-5 h-5" />
                  </span>
                  <a href="https://discord.gg/rbjGedNxUd" target="_blank" className="text-dark font-semibold pl-2 text-lg">
                    Join Discord
                  </a>
                </div>
              </Fade>
              <Fade bottom>
                <div className="flex justify-center btngradient px-4 py-2.5 rounded-lg ml-0 sm:ml-4 transition-all hover:shadow-xl">
                  <span className="flex self-center">
                    <FaTwitter className="text-dark w-5 h-5" />
                  </span>
                  <a href="https://twitter.com/supersquatchnft" target="_blank" className="text-dark font-semibold pl-2 text-lg">
                    Check Twitter
                  </a>
                </div>
              </Fade>
            </div>
          </div>
          <div>
            <div className="relative pt-20">
              <Marquee speed={80} gradient={false}>
                <div className="flex">
                  <img src={pic1} alt="" className="w-64 mx-2" />
                  <img src={pic2} alt="" className="w-64 mx-2" />
                  <img src={pic3} alt="" className="w-64 mx-2" />
                  <img src={pic4} alt="" className="w-64 mx-2" />
                  <img src={pic5} alt="" className="w-64 mx-2" />
                  <img src={pic6} alt="" className="w-64 mx-2" />
                </div>
              </Marquee>
            </div>
            <Marquee speed={80} gradient={false} direction="right">
              <div className="flex">
                <img src={pic7} alt="" className="w-64 mx-2" />
                <img src={pic8} alt="" className="w-64 mx-2" />
                <img src={pic9} alt="" className="w-64 mx-2" />
                <img src={pic10} alt="" className="w-64 mx-2" />
                <img src={pic11} alt="" className="w-64 mx-2" />
                <img src={pic12} alt="" className="w-64 mx-2" />
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
