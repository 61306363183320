import logo from "../assets/images/SS Logo 1.png";
import { useState } from "react";
import './nav.css'
const NavbarMint = () => {
  const [isActiveMint, setIsActiveMint] = useState(false)
  return (
    <div className="w-full bg-white shadow-xl">
      <div className="fixed left-0 right-0 top-0 py-6 px-4 bg-white z-50">
        <div className="max-w-6xl mx-auto px-2 relative">
          <div className="flex justify-between">
            <div>
              <a href="/" ><img src={logo} alt="" className="" /></a>
              
            </div>
            <div className="flex self-center">
              <ul className="flex justify-between">
                
                <li className="px-2.5">
                  <div className="mintDropDown">
                  <button onClick = {()=>{setIsActiveMint(!isActiveMint)}}>MINT LAB</button>
                    <div className="dropList" style = {{height: isActiveMint === true ? '6.5rem': '0'}}>
                      <ul>
                        <li><a href="/vippass" className="" onClick = {()=>{setIsActiveMint(false)}}>VIP Pass</a></li>
                        <li><a href="/squatchgames" className=""onClick = {()=>{setIsActiveMint(false)}}>Squatch Games</a></li>
                        <li><a href="/mintlab" className=""onClick = {()=>{setIsActiveMint(false)}}>Super Squatch</a></li>
                      </ul>
                     </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarMint;
