import VIP from "../components/VIP";
import FAQ from "../components/FAQ";
import SQUATCH from "../components/SQUATCH";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Join from "../components/Join";
import Story from "../components/Story";
import Banner from "../components/Banner";
import Community from "../components/Community";
import Team from "../components/Team";
import Subscribe from "../components/Subscribe";
import Roadmap from "../components/Roadmap";
import SquatchBanner from "../components/SquatchBanner";

const Home = () => {
  return (
    <div>
      <Layout>
        <div id="home">
          <Header />
        </div>
        <div id="VIP">
          <VIP />
        </div>
        <div id="SQUATCH">
          <Join />
        </div>
        <div>
          <SQUATCH />
        </div>
        <div>
          <SquatchBanner />
        </div>
        <div id="STORY">
          <Story />
        </div>
        <div id="Community">
          <Banner />
        </div>
        <div>
          <Community />
        </div>
        <div id="ROADMAP">
          <Roadmap />
        </div>
        <div id="TEAM">
          <Team />
        </div>
        <div id="FAQ">
          <FAQ />
        </div>
        <div>
          <Subscribe />
        </div>
      </Layout>
    </div>
  );
};

export default Home;
