import {FaTwitter, FaInstagram, FaYoutube} from "react-icons/fa";
import {SiTiktok} from "react-icons/si";

const Footer = () => {
  return (
    <div className="w-full bg-white border-t border-secondary">
      <div className="flex justify-center py-8">
        <div>
          <p className="text-center railway text-sm font-normal pb-6 text-dark">
          © Super Squatch 2021 | All Rights Reseved
          </p>
          <div className="flex justify-center">
            <div className="flex">
              <a href="https://twitter.com/supersquatchnft" target="_blank" className="bg-browni p-2 rounded-full cursor-pointer transition-all hover:bg-primary mx-1">
                <FaTwitter className="text-2xl text-white w-6 h-6" />
              </a>
              <a href="https://www.tiktok.com/@supersquatch.io" target="_blank" className="bg-browni p-2 rounded-full cursor-pointer transition-all hover:bg-primary mx-1">
                <SiTiktok className="text-2xl text-white w-6 h-6" />
              </a>
              <a href="https://www.instagram.com/supersquatch.io/" target="_blank" className="bg-browni p-2 rounded-full cursor-pointer transition-all hover:bg-primary mx-1">
                <FaInstagram className="text-2xl text-white w-6 h-6" />
              </a>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
