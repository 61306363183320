import gif from "../assets/images/promo (1).gif";
const Fade = require("react-reveal/Fade")
const Community = () => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-6xl mx-auto px-4 py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="order-last md:order-first">
          <Fade bottom>
            <img src={gif} alt="" />
            </Fade>
          </div>
          <div className="pt-7">
            <Fade bottom>
              <h3 className="text-4xl text-dark font-bold pb-4">
                A Community Project Like No Other
              </h3>
            </Fade>
            <Fade bottom>
              <p className="text-secondary text-xl font-semibold pb-7">
                Our holders will have unique and exciting benefits, because we
                believe our holders deserve only the best.
              </p>
            </Fade>
            <Fade bottom>
              <p className="text-secondary text-xl font-semibold pb-7">
                Our community is set up in such a way that they will remain
                engaged and have the ability to participate and vote on certain
                aspects of the project.
              </p>
            </Fade>
            <Fade bottom>
              <p className="text-secondary text-xl font-semibold pb-7">
                All Super Squatch holders will receive rewards from the Squatch
                Reserve Bank.
              </p>
            </Fade>
            <Fade bottom>
              <p className="text-secondary text-xl font-semibold pb-7">
                And of course we have a lot of giveways, prizes and raffles
                lined up for our members.
              </p>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
