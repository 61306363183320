import React from "react";
import {Router, Route, Redirect, Switch} from "react-router-dom";
import Home from "./pages/Home";
import MintLab from "./pages/MintLab";
import MintLabThree from "./pages/MintLabThree";
import Vippass from "./pages/Vippass";

import { Toaster } from "react-hot-toast";
import { useEagerConnect } from "hooks/useEagerConnect";

function App() {
  useEagerConnect();
  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          success: { duration: 3000 },
          error: { duration: 3000 },
        }}
      />
      <div>
      <Route exact path="/" component={Home} />
      <Route path="/squatchgames" component={MintLab} />
      <Route path="/mintlab" component={MintLabThree} />
      <Route path="/vippass" component={Vippass} />
    </div>
    </>
  );
}

export default App;
