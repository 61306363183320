import portal1 from "../assets/images/tube 1.png";
import portal2 from "../assets/images/portal 1.png";
const Fade = require("react-reveal/Fade")
const Story = () => {
  return (
    <div className="w-full bg-white">
      <div className="max-w-6xl mx-auto px-4 pb-14 md:pb-28 pt-20">
        <Fade bottom>
          <h1 className="text-primary text-center text-6xl pb-20 font-bold">
            Story
          </h1>
        </Fade>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14 mb-40">
          <div>
            <Fade bottom>
              <img src={portal1} alt="" />
            </Fade>
          </div>
          <div>
            <Fade bottom>
              <p className="text-secondary text-xl font-semibold pb-10">
                Little is known about the squatch, but it is rumored, that they
                lived on a planet from another dimension called planet Squatch.
              </p>
            </Fade>
            <Fade bottom>
              <p className="text-secondary text-xl font-semibold pb-10">
                One day a group of squatch discovered an abandoned cave. They
                noticed something odd about this unkown cave and as they began
                to inspect, they came across a strange lab with extremely
                sophisticated equipment.
              </p>
            </Fade>
            <Fade bottom>
              <p className="text-secondary text-xl font-semibold pb-10">
                Due to the curious nature of the squatch, they decided to
                inspect further and began messing around with the unfamiliar
                equipment.
              </p>
            </Fade>
            <Fade bottom>
              <p className="text-secondary text-xl font-semibold pb-10">
                One of them got into what later became known as the eveolution
                chamber. Somehow the chamber had become active and after a few
                moments this particular squatch came out with unspeakable
                abilities.
              </p>
            </Fade>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14">
          <div className="flex pt-10">
            <div>
              <Fade bottom>
                <p className="text-secondary text-xl font-semibold pb-4">
                  That was not all. They noticed the portal like gateway began
                  to glow and above it appeared Only Super Squatch May Enter.
                </p>
              </Fade>
              <Fade bottom>
                <p className="text-secondary text-xl font-semibold pb-4">
                  Little did they know, this portal is linked with many other
                  dimensions.
                </p>
              </Fade>
              <Fade bottom>
                <p className="text-secondary text-xl font-semibold pb-4">
                  Once they step through the gateway_their lives would never be
                  the same and they would take on their new roles.
                </p>
              </Fade>
              <Fade bottom>
                <p className="text-secondary text-xl font-semibold pb-4">
                  Some which may be familiar to us and some which we've never
                  seen before.
                </p>
              </Fade>
            </div>
          </div>
          <div className="order-first md:order-last">
            <Fade bottom>
              <img src={portal2} alt="" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
