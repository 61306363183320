import React from "react";
// import {Fade} from "react-reveal";
import {Collapse} from "antd";
import {PlusOutlined, MinusOutlined} from "@ant-design/icons";
const Fade = require("react-reveal/Fade")
const {Panel} = Collapse;

const FAQ = () => {
  return (
    <div>
      <div className="w-full bg-white">
        <div className="max-w-6xl mx-auto px-4 py-10">
          <Fade bottom>
            <h1 className="text-primary text-center text-lg pb-3 font-bold">
              Ask Away
            </h1>
            <h3 className="text-4xl text-center text-dark font-bold pb-10">
              Frequently Asked Questions
            </h3>
            <div className="mb-14">
              <Collapse
                bordered={false}
                expandIcon={({isActive}) =>
                  <PlusOutlined
                    rotate={isActive ? 360 : 0}
                    className={isActive ? "text-offWhite" : " text-offWhite"}
                    style={{fontSize: "150%"}}
                  />}
                expandIcon={({isActive}) =>
                  isActive
                    ? <MinusOutlined
                        className={
                          isActive ? "text-secondary" : " text-secondary"
                        }
                        style={{fontSize: "150%"}}
                      />
                    : <PlusOutlined
                        className={isActive ? "text-primary" : " text-primary"}
                        style={{fontSize: "150%"}}
                      />}
                expandIconPosition="right"
                className="site-collapse-custom-collapse bg-white"
              >
                {generalQuestion.map(item => {
                  return (
                    <Panel
                      header={item.title}
                      key={item.id}
                      className="text-offWhite  mb-4"
                    >
                      <Fade>
                        <p className="pr-10 text-base font-medium text-dark py-7">
                          {item.description}
                        </p>
                      </Fade>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default FAQ;

const generalQuestion = [
  {
    id: 1,
    title: "When is the First Drop date?",
    description: `The First Drop date will be sometime in November, for more information be sure to join the discord and follow the official social links.`
  },
  {
    id: 2,
    title: "How can we make a purchase and is there a limit?",
    description: `You can mint on the launch date, via the Mint Lab. There is a limit which will be visible on the Mint Lab page, depending on the drop. `
  },
  {
    id: 3,
    title: "What is a Flash Drop?",
    description: ` A flash drop is a limited edition collection of NFTs. They are not present within the main drop.`
  },
  {
    id: 4,
    title: "What's the price?",
    description: `Please refer to the roadmap for drop pricing.`
  },
  {
    id: 5,
    title: "Can we join the Super Squatch team?",
    description: `If you're interested, you can apply on the discord. Be sure to tell us what skills you have and why we should let you join the team.`
  }
];
