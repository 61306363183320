import Footer from "./Footer";
import MobileMenu from "./MobileMenu";
import Navbar from "./Navbar";

const Layout = ({children}:any) => {
  return (
    <div>
      <div className="hidden xl:block">
        <Navbar />
      </div>
      <div className="block xl:hidden">
        <MobileMenu />
      </div>

      {children}
      <Footer />
    </div>
  );
};

export default Layout;
