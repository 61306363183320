import vipPic from "../../src/assets/images/Attachment_1638559871.png";
import { useEffect, useState } from "react";
import ConnectModal from './connectModal/ConnectModal';
import './mint.scss';
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import { purchaseGold } from "utils/contracts";
import { truncateWalletString } from "utils";

const Fade = require("react-reveal/Fade")
const Mint = () => {
  const [showConnectModal, setShowConnectModal] = useState(false)

  const [mintCount, setMintCount] = useState(1);
  const decreaseHandle = () => {
    if (mintCount > 1) {
      setMintCount(mintCount - 1)
    }

  }
  const increaseHandle = () => {
    if (mintCount < 1) {
      setMintCount(mintCount + 1)
    }
  }

  const [loginStatus, setLoginStatus] = useState(false);
  const { connector, library, chainId, account, active } = useWeb3React();
  useEffect(() => {
    const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId]);

  const mintGoldTokens = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }

    if (mintCount <= 0) {
      toast.error("Mint Count should be over than zero!");
      return;
    }

    const load_toast_id = toast.loading("Please wait for Mint...");
    try {
      const bSuccess = await purchaseGold(chainId, library.getSigner(), account, mintCount);
      if (bSuccess) {
        toast.success("Mint Success!");
      } else {
        toast.error("Mint Failed!");
      }
    } catch (error) {
      toast.error("Mint Failed!");
    }
    toast.dismiss(load_toast_id);
  };


  return (
    <div className=" w-full vipMint">
      <ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
      <div className="mintContent">
        <Fade bottom>
          <h1 className="text-primary text-center text-xl pb-3 font-bold">
            Welcome
          </h1>
        </Fade>
        <Fade bottom>
          <h3 className="react-reveal text-4xl text-center bg-white text-dark font-bold py-5 px-4 uppercase" >Mint Yourself A SQUATCH PASS</h3>
        </Fade>
        <Fade bottom>
          <p className="text-secondary text-center text-dark uppercase text-xl font-semibold pb-4">
            ONLY 0.03 ETH
          </p>
        </Fade>
        <div className="wrapper">
          <div className="left">
            <div className="leftContent flex justify-center mb-10">
              <Fade bottom>
                <img src={vipPic} alt="" className="w-full md:w-96" />
              </Fade>
            </div>
            <Fade bottom>
              <div className="connectButton flex justify-center btngradient w-48 px-4 mb-4 sm:mb-0  py-2.5 rounded-lg transition-all hover:shadow-xl" onClick={() => { !loginStatus && setShowConnectModal(true) }}>
                <div className="text-dark font-semibold  text-lg"  > {loginStatus ? truncateWalletString(account) : "Connect Wallet"}
                </div>
              </div>
            </Fade>
          </div>
          <div className="right">
            <div className="mintCount">
              <div className="btns">
                <button className="mintDecrease" onClick={decreaseHandle}><i className="fas fa-minus"></i></button>
                <span className="mintCountValue">{mintCount}</span>
                <button className="mintIncrease" onClick={increaseHandle} ><i className="fas fa-plus"></i></button>
                <Fade bottom>
                  <div className="mintButton flex ml-4 justify-center btngradient w-48 px-4 mb-4 sm:mb-0  py-2.5 rounded-lg transition-all hover:shadow-xl" onClick={mintGoldTokens}>
                    <button className="text-dark font-semibold  text-lg">
                      Mint
                    </button>
                  </div>
                </Fade>
              </div>

              <div className="mintCost">
                <span>Price : 0.03ETH</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Mint;
