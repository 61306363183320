import LayoutMint from "components/LayoutMint";
import TwoEth from "../components/TwoEth";
const MintLab = () => {
  return (
    <div>
      <LayoutMint>
        <TwoEth />
      </LayoutMint>
    </div>
  );
};

export default MintLab;
