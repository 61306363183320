import pic1 from "../../src/assets/images/1.png";
import pic2 from "../../src/assets/images/2.png";
import pic3 from "../../src/assets/images/3.png";
import pic4 from "../../src/assets/images/4.png";
import pic5 from "../../src/assets/images/5.png";
import pic6 from "../../src/assets/images/6.png";
const Fade = require("react-reveal/Fade")
const SQUATCH = () => {
  return (
    <>
    <div className="w-full bg-white">
      <div className="max-w-5xl mx-auto px-4 py-10">
      <Fade bottom>
        <h1 className="text-primary text-center text-xl pb-3 font-bold">
         We're Just Getting Started
        </h1>
        </Fade>
        <Fade bottom>
        <h3 className="text-4xl capitalize text-center text-dark font-bold pb-6">
          Check Em Out
        </h3>
        </Fade>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {data.map((item, index) => {
            return (
              <div key={index}>
                 <Fade bottom>
                <img src={item.picture} alt="" className="w-full" />
                </Fade>
              </div>
            );
          })}
        </div>
      </div>
    </div>
   
    </>
  );
};

export default SQUATCH;

const data = [
  {
    picture: pic1
  },
  {
    picture: pic2
  },
  {
    picture: pic3
  },
  {
    picture: pic4
  },
  {
    picture: pic5
  },
  {
    picture: pic6
  }
];
