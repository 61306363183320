import {FaTelegramPlane} from "react-icons/fa";
import batman from "../../src/assets/images/batmangifweb 1.png";
const Fade = require("react-reveal/Fade")
const Banner = () => {
  return (
    <div className="w-full bg-purple">
      <div className="max-w-6xl mx-auto px-4 pt-10">
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="flex self-center col-span-2">
            <div>
              <Fade bottom>
                <h1 className="text-primary text-xl pb-5 font-bold">
                  Join our discord
                </h1>
              </Fade>
              <Fade bottom>
                <h3 className="lineheight65 text-white font-bold pb-20">
                  Become part of our fun<br /> and growing community
                </h3>
              </Fade>
              <Fade bottom>
                <div className="flex">
                  <div className="flex justify-center btngradient px-4 py-2.5 rounded-lg transition-all hover:shadow-xl">
                    <span className="flex self-center">
                      <FaTelegramPlane className="text-dark w-5 h-5" />
                    </span>
                    <a href="https://discord.gg/rbjGedNxUd" target="_blank" className="text-dark font-semibold pl-2 text-lg">
                      Join Discord
                    </a>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="flex items-end self-end -mb-0.5">
            <Fade bottom>
              <img src={batman} alt="" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
