import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

// Assets
import pic from "../assets/images/new 1.png";
const Roadmap = () => {
  return (
    <div className="w-full bg-lightBlue">
      <div className="max-w-6xl mx-auto py-24 px-4">
        <h1 className="text-dark text-3xl md:text-4xl font-bold text-center  cursor-pointer pb-10 md:pb-3">
          Season 1 Roadmap
        </h1>
        <div className="flex justify-center">
          <div className="">
            <VerticalTimeline>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#00B1EC",
                  color: "#fff"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00B1EC"
                }}
                iconStyle={{background: "#00B1EC", color: "#fff"}}
              >
                <h3 className="text-white railway font-semibold text-3xl pb-3">
                  First Drop: VIP
                </h3>

                <p className="text-white railway font-semibold text-xl pb-3">
                  300 Super Squatch Passes at 0.03 ETH
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#00B1EC",
                  color: "#fff"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00B1EC"
                }}
                iconStyle={{background: "#00B1EC", color: "#fff"}}
              >
                <h3 className="text-white railway font-semibold text-3xl pb-3">
                  Flash Drop: The Squatch Games
                </h3>

                <p className="text-white railway font-semibold text-xl pb-3">
                  1st lucky holder 20 ETH <br />
                  2nd lucky holder 12 ETH <br />
                  3rd lucky holder 2 ETH <br />
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#00B1EC",
                  color: "#fff"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00B1EC"
                }}
                iconStyle={{background: "#00B1EC", color: "#fff"}}
              >
                <h3 className="text-white railway font-semibold text-3xl pb-3" />

                <p className="text-white railway font-semibold text-xl pb-3">
                  Remaining 453 holders Will receive 0.02 ETH for participating.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#00B1EC",
                  color: "#fff"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00B1EC"
                }}
                iconStyle={{background: "#00B1EC", color: "#fff"}}
              >
                <h3 className="text-white railway font-semibold text-3xl pb-3">
                  Rules of the game:
                </h3>

                <p className="text-white railway font-semibold text-xl pb-3">
                  All 456 particpants plus 4 additional members must be minted
                  and sold out.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#00B1EC",
                  color: "#fff"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00B1EC"
                }}
                iconStyle={{background: "#00B1EC", color: "#fff"}}
              >
                <h3 className="text-white railway font-semibold text-3xl pb-3">
                  Main Drop: The Super Squatch
                </h3>

                <p className="text-white railway font-semibold text-xl pb-3">
                  10,000 Super Squatch enter the mint lab at 0.05 ETH
                </p>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#00B1EC",
                  color: "#fff"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00B1EC"
                }}
                iconStyle={{background: "#00B1EC", color: "#fff"}}
              >
                <h3 className="text-white railway font-semibold text-3xl pb-3">
                  Rewards: The Community Squatch Reserve Bank
                </h3>

                <p className="text-white railway font-semibold text-xl pb-3">
                  The first official reward distribution to holders of Super
                  Squatch.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#00B1EC",
                  color: "#fff"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00B1EC"
                }}
                iconStyle={{background: "#00B1EC", color: "#fff"}}
              >
                <h3 className="text-white railway font-semibold text-3xl pb-3" />

                <p className="text-white railway font-semibold text-xl pb-3">
                  The SRB is a community bank/ wallet. The Super Squatch team
                  will be setting aside 20% of net profit into this wallet, and
                  the rewards will be distributed quarterly to all holders. Each
                  quarter 20% will be distributed to each and every holder.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#00B1EC",
                  color: "#fff"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00B1EC"
                }}
                iconStyle={{background: "#00B1EC", color: "#fff"}}
              >
                <h3 className="text-white railway font-semibold text-3xl pb-3">
                  Season 2 Roadmap
                </h3>

                <p className="text-white railway font-semibold text-xl pb-3">
                  If you liked Season 1, you're going to want stick around for
                  what we have in store for Season 2!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: "#00B1EC",
                  color: "#fff"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #00B1EC"
                }}
                iconStyle={{background: "#00B1EC", color: "#fff"}}
              >
                <h3 className="text-white railway font-semibold text-3xl pb-3" />

                <p className="text-white railway font-semibold text-xl pb-3">
                  Shh... for now it's a secret, but we may drop some clues in
                  our discord. Don't forget to join!
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
