const Fade = require("react-reveal/Fade")
const Subscribe = () => {
  return (
    <div className="w-full bg-white mb-10">
      <Fade bottom>
        <div className="max-w-6xl mx-auto px-8 py-10 linearGradient shadow-md rounded-md">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex self-center">
              <h1 className="text-white font-semibold text-2xl pb-4 md:pb-0">
                Subscribe
              </h1>
            </div>
            <div className="flex justify-center w-full self-center">
              <input
                type="text"
                placeholder="Enter your email"
                className="bg-white rounded-md mb-4 md:mb-0 font-medium w-full flex md:w-96 py-3.5 px-4 placeholder-secondary focus-within:outline-none"
              />
            </div>
            <div className="flex justify-center w-full self-center">
              <a className="bg-white w-full font-semibold text-center rounded-md text-dark px-8 py-3 hover:text-dark transition-all hover:shadow-xl">
                Register Now
              </a>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Subscribe;
