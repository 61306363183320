import vipPic from "../../src/assets/images/joinp.png";
const Fade = require("react-reveal/Fade")
const Join = () => {
  return (
    <div className=" w-full bg-white">
      <div className="max-w-6xl mx-auto px-4 py-14">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex self-center">
            <div>
              <Fade bottom>
                <h1 className="text-primary text-xl pb-3 font-bold">Join</h1>
              </Fade>
              <Fade bottom>
                <h3 className="text-4xl text-dark font-bold pb-6">
                  The Squatch
                </h3>
              </Fade>
              <Fade bottom>
                <p className="text-secondary text-xl font-semibold pb-4">
                  The Super Squatch are a growing collection of unique randomly
                  generated squatchs, scattered throughout the Ethereum
                  blockchain.
                </p>
              </Fade>
              <Fade bottom>
                <p className="text-secondary text-xl font-semibold pb-4">
                  Each Super Squatch is awesome and unique with over 585+
                  possible attributes and traits like, background, fur style,
                  clothes, head gear, fur color, mouth, eyes, rarity and much
                  more.
                </p>
              </Fade>
              <Fade bottom>
                <p className="text-secondary text-xl font-semibold pb-4">
                  Owning a Super Squatch makes you a part of the squatch family.
                  Giving you exclusive access to unthinkable benefits.
                </p>
              </Fade>
            </div>
          </div>
          <div className="flex justify-center md:justify-end">
            <Fade bottom>
              <img src={vipPic} alt="" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Join;
