import logo from "../assets/images/SS Logo 1.png";
import { useState } from "react";
import './nav.css'
const Navbar = () => {
  const [isActiveMint, setIsActiveMint] = useState(false)
  return (
    <div className="w-full bg-white shadow-xl">
      <div className="fixed left-0 right-0 top-0 py-6 px-4 bg-white z-50">
        <div className="max-w-6xl mx-auto px-2 relative">
          <div className="flex justify-between">
            <div>
              <a href="/" ><img src={logo} alt="" className="" /></a>
              
            </div>
            <div className="flex self-center">
              <ul className="flex justify-between">
                <li className="px-2.5">
                  {/* <AnchorLink href="#home"> */}
                    <a href="/" className="railway text-dark transition-all hover:text-primary font-semibold cursor-pointer">
                      HOME
                    </a>
                  {/* </AnchorLink> */}
                </li>
                <li className="px-2.5">
                  {/* <AnchorLink href="#VIP"> */}
                    <a href="/#VIP" className="railway text-dark transition-all hover:text-primary font-semibold cursor-pointer">
                      VIP
                    </a>
                  {/* </AnchorLink> */}
                </li>
                <li className="px-2.5">
                  {/* <AnchorLink href="#SQUATCH"> */}
                    <a href="/#SQUATCH" className="railway text-dark transition-all hover:text-primary font-semibold cursor-pointer">
                      SQUATCH
                    </a>
                  {/* </AnchorLink> */}
                </li>
                <li className="px-2.5">
                  {/* <AnchorLink href="#STORY"> */}
                    <a href="/#STORY" className="railway text-dark transition-all hover:text-primary font-semibold cursor-pointer">
                      STORY
                    </a>
                  {/* </AnchorLink> */}
                </li>
                <li className="px-2.5">
                  {/* <AnchorLink href="#Community"> */}
                    <a href="/#Community" className="railway text-dark transition-all hover:text-primary font-semibold cursor-pointer">
                      COMMUNITY
                    </a>
                  {/* </AnchorLink> */}
                </li>
                <li className="px-2.5">
                  {/* <AnchorLink href="#ROADMAP"> */}
                    <a href="/#ROADMAP" className="railway text-dark transition-all hover:text-primary font-semibold cursor-pointer">
                      ROADMAP
                    </a>
                  {/* </AnchorLink> */}
                </li>
                <li className="px-2.5">
                  {/* <AnchorLink href="#TEAM"> */}
                    <a href="/#TEAM" className="railway text-dark transition-all hover:text-primary font-semibold cursor-pointer">
                      TEAM
                    </a>
                  {/* </AnchorLink> */}
                </li>
                <li className="px-2.5">
                  {/* <AnchorLink href="#FAQ"> */}
                    <a href="/#FAQ" className="railway text-dark transition-all hover:text-primary font-semibold cursor-pointer">
                      FAQ
                    </a>
                  {/* </AnchorLink> */}
                </li>
                <li className="px-2.5">
                  <div className="mintDropDown">
                  <button onClick = {()=>{setIsActiveMint(!isActiveMint)}}>MINT LAB</button>
                    <div className="dropList" style = {{height: isActiveMint === true ? '6.5rem': '0'}}>
                      <ul>
                        <li><a href="/vippass" className="" onClick = {()=>{setIsActiveMint(false)}}>VIP Pass</a></li>
                        <li><a href="/squatchgames" className=""onClick = {()=>{setIsActiveMint(false)}}>Squatch Games</a></li>
                        <li><a href="/mintlab" className=""onClick = {()=>{setIsActiveMint(false)}}>Super Squatch</a></li>
                      </ul>
                     </div>
                  </div>
                  
                  {/* <a
                    href="#"
                    className="text-white bg-primary px-5 py-2.5 rounded-full transition-all hover:text-primary font-semibold cursor-pointer"
                  >
                    MINT LAB
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
