import LayoutMint from "components/LayoutMint";
import ThreeEth from "../components/ThreeEth";
const MintLabThree = () => {
  return (
    <div>
      <LayoutMint>
        <ThreeEth />
      </LayoutMint>
    </div>
  );
};

export default MintLabThree;
