import giff from "../../src/assets/images/Webp.net-gifmaker.gif";
import ConnectModal from './connectModal/ConnectModal';
import { useEffect, useState } from "react";
import './mint.scss'
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import { purchaseGold, purchasePrize, purchasePublic } from "utils/contracts";
import { truncateWalletString } from "utils";
const Fade = require("react-reveal/Fade")
const TwoEth = () => {
	const [showConnectModal, setShowConnectModal] = useState(false)

	const [mintCount, setMintCount] = useState(1);
	const decreaseHandle = () => {
		if (mintCount > 0) {
			setMintCount(mintCount - 1)
		}

	}
	const increaseHandle = () => {
		if (mintCount < 5) {
			setMintCount(mintCount + 1)
		}
	}


	const [loginStatus, setLoginStatus] = useState(false);
	const { connector, library, chainId, account, active } = useWeb3React();
	useEffect(() => {
		const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
		setLoginStatus(isLoggedin);
	}, [connector, library, account, active, chainId]);

	const mintPrizeTokens = async () => {
		if (!loginStatus) {
			toast.error("Please connect wallet correctly!");
			return;
		}

		if (mintCount <= 0) {
			toast.error("Mint Count should be over than zero!");
			return;
		}

		const load_toast_id = toast.loading("Please wait for Mint...");
		try {
			const bSuccess = await purchasePrize(chainId, library.getSigner(), account, mintCount);
			if (bSuccess) {
				toast.success("Mint Success!");
			} else {
				toast.error("Mint Failed!");
			}
		} catch (error) {
			toast.error("Mint Failed!");
		}
		toast.dismiss(load_toast_id);
	};

	return (
		<div>
			<div className=" w-full ethbg">
				<ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
				<div className="mintContent">
					<Fade bottom>
						<h3 className="ethbgTitle text-5xl text-center bg-dark text-white font-bold py-10 px-4   uppercase">
							Mint Yourself A SQUATCH For your chance to
							win Only 0.2 ETH
						</h3>
					</Fade>
					<div className="wrapper">
						<div className="left">
							<div className="leftContent flex justify-center my-20 ">
								<Fade bottom>
									<img src={giff} alt="" className="w-full md:w-96" />
								</Fade>

							</div>
							<Fade bottom>
								<div className="connectButton flex justify-center btngradient w-48 px-4 mb-4 sm:mb-0  py-2.5 rounded-lg transition-all hover:shadow-xl" onClick={() => { !loginStatus && setShowConnectModal(true) }}>
									<div className="text-dark font-semibold  text-lg" > {loginStatus ? truncateWalletString(account) : "Connect Wallet"}  </div>
								</div>
							</Fade>
						</div>
						<div className="right">
							<div className="mintCount">
								<div className="btns">
									<button className="mintDecrease" onClick={decreaseHandle}><i className="fas fa-minus"></i></button>
									<span className="mintCountValue ethCount">{mintCount}</span>
									<button className="mintIncrease" onClick={increaseHandle} ><i className="fas fa-plus"></i></button>
									<Fade bottom>
										<div className="mintButton flex ml-4 justify-center btngradient w-48 px-4 mb-4 sm:mb-0  py-2.5 rounded-lg transition-all hover:shadow-xl" onClick={mintPrizeTokens}>
											<button className="text-dark font-semibold  text-lg">
												Mint
											</button>
										</div>
									</Fade>
								</div>

								<div className="mintCost ethCost">
									<span>Price : 0.2ETH</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TwoEth;
