import LayoutMint from "components/LayoutMint";
import Mint from "../components/Mint";
const MintLabThree = () => {
  return (
    <div>
      <LayoutMint>
        <Mint/>
      </LayoutMint>
    </div>
  );
};

export default MintLabThree;
