import superSquahs from "../../src/assets/images/bg_new-03-03 1.png"
const Fade = require("react-reveal/Fade")
const SquatchBanner = () => {
  return (
    <div>
      <div className="w-full">
        <Fade bottom>
          <div>
            <img src={superSquahs} alt="" className="w-full" />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default SquatchBanner;
