import giff from "../../src/assets/images/promo (1).gif";
import ConnectModal from './connectModal/ConnectModal';
import { useEffect, useState } from "react";
import './mint.scss'
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import { purchaseGold, purchasePrize, purchasePublic } from "utils/contracts";
import { truncateWalletString } from "utils";
const Fade = require("react-reveal/Fade")
const ThreeEth = () => {
  const [showConnectModal, setShowConnectModal] = useState(false)

  const [mintCount, setMintCount] = useState(1);
  const decreaseHandle = () => {
    if (mintCount > 0) {
      setMintCount(mintCount - 1)
    }

  }
  const increaseHandle = () => {
    if (mintCount < 20) {
      setMintCount(mintCount + 1)
    }
  }

  const [loginStatus, setLoginStatus] = useState(false);
  const { connector, library, chainId, account, active } = useWeb3React();
  useEffect(() => {
    const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
    setLoginStatus(isLoggedin);
  }, [connector, library, account, active, chainId]);

  const mintPublicTokens = async () => {
    if (!loginStatus) {
      toast.error("Please connect wallet correctly!");
      return;
    }

    if (mintCount <= 0) {
      toast.error("Mint Count should be over than zero!");
      return;
    }

    const load_toast_id = toast.loading("Please wait for Mint...");
    try {
      const bSuccess = await purchasePublic(chainId, library.getSigner(), account, mintCount);
      if (bSuccess) {
        toast.success("Mint Success!");
      } else {
        toast.error("Mint Failed!");
      }
    } catch (error) {
      toast.error("Mint Failed!");
    }
    toast.dismiss(load_toast_id);
  };

  return (
    <div>
      <div className=" w-full ethbg-three">
        <ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
        <div className="mintContent">
          <Fade bottom>
            <h1 className="text-white text-center text-3xl pb-3 font-bold">
              Welcome
            </h1>
          </Fade>
          <Fade bottom>
            <h3 className="text-5xl text-center text-white font-bold pb-6 uppercase">
              Mint yourself a super Squatch
            </h3>
          </Fade>
          <Fade bottom>
            <p className="text-white text-center uppercase text-3xl font-semibold pb-4">
              Only 0.05 ETH
            </p>
          </Fade>
          <div className="wrapper">
            <div className="left">
              <div className="leftContent flex justify-center my-20 ">
                <Fade bottom>
                  <img src={giff} alt="" className="w-full md:w-96" />
                </Fade>
              </div>
              <div className="flex justify-center">
                <Fade bottom>
                  <div className="connectButton flex justify-center btngradient w-48 px-4 mb-4 sm:mb-0  py-2.5 rounded-lg transition-all hover:shadow-xl" onClick={() => { !loginStatus && setShowConnectModal(true) }}>
                    <div className="text-dark font-semibold  text-lg" > {loginStatus ? truncateWalletString(account) : "Connect Wallet"}  </div>
                  </div>
                </Fade>

              </div>
            </div>
            <div className="right">
              <div className="mintCount">
                <div className="btns">
                  <button className="mintDecrease" onClick={decreaseHandle}><i className="fas fa-minus"></i></button>
                  <span className="mintCountValue">{mintCount}</span>
                  <button className="mintIncrease" onClick={increaseHandle} ><i className="fas fa-plus"></i></button>
                  <Fade bottom>
                    <div className="mintButton flex ml-4 justify-center btngradient w-48 px-4 mb-4 sm:mb-0  py-2.5 rounded-lg transition-all hover:shadow-xl" onClick={mintPublicTokens}>
                      <button className="text-dark font-semibold  text-lg">
                        Mint
                      </button>
                    </div>
                  </Fade>
                </div>

                <div className="mintCost">
                  <span>Price : 0.05ETH</span>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ThreeEth;
